readUrl(window.location.href)

document.addEventListener("scroll", function () {
    if (window.scrollY > 100) {
        document.body.classList.add("show-scroll-button")
        document.body.classList.remove("remove-scroll-button")
        return
    }
    document.body.classList.remove("show-scroll-button")
    setTimeout(function () {
        if (window.scrollY <= 100) {
            document.body.classList.add("remove-scroll-button")
        }
    }, 300)
})

if (!['/thank-you'].includes(window.location.pathname)) {

    for(const button of document.querySelectorAll(".box-pricing .get-started")) {

        button.addEventListener('click', function (e) {
            
            const selected = document.querySelector('input[name="subscription"]:checked')

            const parent = e.target.closest(".box-pricing")
            const { options } = parent.dataset
            
            const plan = parent.querySelector('h3').textContent
            const [monthly, yearly] = options.split('`')

            if (selected.value === "m") {

                openDialog(
                    plan,
                    `then <b>£${monthly}</b> / month`,
                    `${plan} - 14 days FREE trial then £${monthly} / month`
                )

                return
            }

            const yearly_total = yearly * 12

            openDialog(
                plan,
                `then <b>£${yearly_total}</b> / year <small class="opacity-50">(£${yearly} / month)</small>`,
                `${plan} - 14 days FREE trial then £${yearly_total} / year (£${yearly} / month)`
            )

        })
    }

    const url = new URL(window.location.href)

    if (url.searchParams.has('plan')) {


        const plan = url.searchParams.get('plan')

        openCheckout(plan)

        url.searchParams.delete('plan')
        history.replaceState({}, "", url)

    }
    
    for(const link of document.querySelectorAll("a[data-plan]")) {
    
        link.addEventListener('click', function (e) {
            e.preventDefault()
            openCheckout(e.target.dataset.plan)
        })
    
    }

    for(const link of document.querySelectorAll('[href*="#"]:not([data-plan])')) {
    
        link.addEventListener('click', function (e) {
            e.preventDefault()
    
            if (e.target.hash === '#top') {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                })
                return
            }
            
    
            readUrl(e.target.href)
            history.pushState({}, "", e.target.href)
            
        })
    }
}


for(const button of document.querySelectorAll('[data-action="hide-dialog"]')) {

    button.addEventListener('click', function (e) {
        e.preventDefault()

        hideDialog()

        if (e.currentTarget.hash) {
            document.querySelector(`${e.currentTarget.hash}`).scrollIntoView()
        }
    })
}

function openCheckout (plan) {
    const button = document.querySelector(`.box-pricing[data-plan="${plan}"] .cta`)
    button?.click()
}

function openDialog (plan, priceDescription, message) {
    dialog.querySelector('.price-plan-name').textContent = plan
    dialog.querySelector(".price-description").innerHTML = priceDescription
    dialog.querySelector('input[name="message"]').value = message
    dialog.dataset.state = 'open'
    document.body.style.overflow = 'hidden'
    dialog.querySelector('[name="fullname"]').focus()
}

function hideDialog () {
    dialog.querySelector('.price-plan-name').textContent = ""
    dialog.querySelector(".price-description").innerHTML = ""
    dialog.querySelector('input[name="message"]').value = ""
    dialog.dataset.state = 'closed'
    document.body.style.overflow = 'auto'   
}

function readUrl (href) {

    const url = new URL(href)

    window[url.hash?.substring(1)]?.scrollIntoView()

    switch (url.hash.substring(1)) {
        case "contact":
            if (!contact_form) break
            contact_form.elements.request.value = url.searchParams.get('request')
            contact_form.elements.fullname.focus()

            break
        default:
        
    }

}